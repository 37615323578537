import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import KibanaIcon from '@/components/assets/icons/Kibana.vue'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: {
            Kibana: { // name of our custom icon
                component: KibanaIcon, // our custom component
            },
        },
    },
});
