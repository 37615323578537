const moduleName = 'user'

export const REQUEST = 'REQUEST'
export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'

export const USER_REQUEST = moduleName + '/' + REQUEST
export const USER_SUCCESS = moduleName + '/' + SUCCESS
export const USER_ERROR = moduleName + '/' + ERROR


export const SET_LOCALE = 'SET_LOCALE'
export const USER_SET_LOCALE = moduleName + '/' + SET_LOCALE