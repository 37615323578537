import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '@/store'
import api from '@/utils/api'
import {REQUEST, ERROR, SUCCESS, LOGOUT, UNAUTHORIZED} from '../actions/auth'
import { USER_ERROR, USER_SUCCESS } from '../actions/user'

Vue.use(VueAxios, axios)

const state = {
  status: '',
  hasLoadedOnce: false
}

export const HTTP = Vue.axios

HTTP.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  if (error.response && error.response.status === 401) {
    console.log(error)
    // store.commit(AUTH_UNAUTHORIZED)
    await Vue.$router.push('/')
  } else if (error.response) {
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log('Error', error.message);
  }
  return Promise.reject(error)
});

HTTP.defaults.baseURL = process.env.VUE_APP_API_URL
HTTP.defaults.headers.common['Authorization'] = localStorage.getItem('user-token')

const getters = {
  isAuthenticated: state => {
    const token = localStorage.getItem('user-token')
    // window.console.log('localStorage', token)
    if (token !== null) {
      if (state.status === 'success') {
        // window.console.log('localStorage success', token)
        return true
      } else if (state.hasLoadedOnce === false) {
        // window.console.log('localStorage hasLoadedOnce', token)
        return true
      } else {
        // window.console.log('localStorage else', token)
        return false
      }
    } else {
      // window.console.log('localStorage XXXX', token)
      return false
    }
  },
  authStatus: state => state.status,
  getToken: state => {
    if (state.status === 'success') {
      const token = localStorage.getItem('user-token')
      // window.console.log('getToken', token)
      if (token) {
        return token
      } else {
        return ''
      }
    } else {
      return ''
    }
  }
}

const actions = {
  [REQUEST]: async ({commit}, component) => {
    commit(REQUEST)
    try {
      debugger; // eslint-disable-line no-debugger

      const authCode = await component.$gAuth.getAuthCode()
      try {
        const response = await api.login(authCode)
        if (response.status === 202) {
          console.info('POST response', response)
          localStorage.setItem('user-token', response.data.token)
          HTTP.defaults.headers.common['Authorization'] = localStorage.getItem('user-token')
          commit(SUCCESS)
          store.commit(USER_SUCCESS, response.data)
          return true
        } else {
          commit(ERROR)
          localStorage.removeItem('user-token')
          console.info('Error', response)
          return false
        }
      } catch (err) {
        console.error(err)
        localStorage.removeItem('user-token')
        store.commit(USER_ERROR)
        commit(ERROR)
        return false
      }
    } catch (err) {
      console.error(err)
      localStorage.removeItem('user-token')
      store.commit(USER_ERROR)
      commit(ERROR)
      return false
    }
  },
  [LOGOUT]: async ({commit}) => {
    try {
      const response = await api.logout()
      console.info('Logout response', response)
      if (response.status === 202) {
        localStorage.removeItem('user-token')
        store.commit(USER_ERROR)
        commit(LOGOUT)
        return true
      } else {
        commit(ERROR)
        localStorage.removeItem('user-token')
        console.info('Error', response)
        store.commit(USER_ERROR)
        commit(LOGOUT)
        return false
      }
    } catch (err) {
      console.error(err)
      localStorage.removeItem('user-token')
      store.commit(USER_ERROR)
      commit(LOGOUT)
      return false
    }
  }
}

const mutations = {
  [REQUEST]: state => {
    state.status = 'loading'
  },
  [SUCCESS]: (state) => {
    state.status = 'success'
    state.hasLoadedOnce = true
  },
  [ERROR]: state => {
    state.status = 'error'
    state.hasLoadedOnce = true
  },
  [UNAUTHORIZED]: state => {
    state.status = 'unauthorized'
    localStorage.removeItem('user-token')
    state.hasLoadedOnce = true
  },
  [LOGOUT]: state => {
    state.status = 'logout'
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}