const moduleName = 'auth'

export const REQUEST = 'REQUEST'
export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'
export const LOGOUT = 'LOGOUT'
export const UNAUTHORIZED = 'UNAUTHORIZED'

export const AUTH_REQUEST = moduleName + '/' + REQUEST
export const AUTH_SUCCESS = moduleName + '/' + SUCCESS
export const AUTH_ERROR = moduleName + '/' + ERROR
export const AUTH_LOGOUT = moduleName + '/' + LOGOUT
export const AUTH_UNAUTHORIZED = moduleName + '/' + UNAUTHORIZED
