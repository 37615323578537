import Vue from 'vue'
import LoadScript from 'vue-plugin-load-script'
import store from './store'
import router from './router'
import App from './App.vue'
import FlagIcon from 'vue-flag-icon'
import vuetify from './plugins/vuetify'
import GAuth from 'vue-google-oauth2'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import stockInit from 'highcharts/modules/stock'
import VueClipboard from 'vue-clipboard2'

import './global.css'

const gauthOption = {
  clientId: '178160266394-s65h9is0gfnma9bmnjk6qotm5f4n7qpv.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}

console.log('NODE_ENV',process.env.NODE_ENV)
if (process.env.NODE_ENV !== 'development') {
  Vue.use(GAuth, gauthOption)
}


Vue.use(FlagIcon)
Vue.use(LoadScript)
Vue.use(HighchartsVue)

VueClipboard.config.autoSetContainer = true

Vue.use(VueClipboard)

stockInit(Highcharts)

Vue.config.productionTip = false


new Vue({
  el: '#app',
  store: store,
  router: router,
  vuetify,
  render: h => h(App)
})
