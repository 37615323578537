import { REQUEST, ERROR, SUCCESS, SET_LOCALE } from '../actions/user'
import Vue from 'vue'
import api from '@/utils/api'
import { AUTH_LOGOUT } from '../actions/auth'
import store from '@/store'

const state = {status: '', profile: {}, settings: {}, security: {}, name: '', config: {}}

const getters = {
  getProfile: state => state.profile,
  isProfileLoaded: state => state.status === 'success',

  getGoogleAvatarImg: state => state.profile.google.picture,
  getGoogleGivenName: state => state.profile.google.given_name,
  getGoogleName: state => state.profile.google.name,

  isAdmin: state => {
    if (state.security.isAdmin) {
      return state.security.isAdmin
    } else {
      return false
    }
  },

  isAllowOptimizeCampaign: state => {
    if (state.security.isAllowOptimizeCampaign) {
      return state.security.isAllowOptimizeCampaign
    } else {
      return false
    }
  },

  isDspModerator: state => {
    if (state.security.isDspModerator) {
      return state.security.isDspModerator
    } else {
      return false
    }
  },

  isVisibleAffNets: state => {
    if (state.security.isVisibleAffNets) {
      return state.security.isVisibleAffNets
    } else {
      return false
    }
  },

  affNets: state => {
    if (state.security.isVisibleAffNets) {
      return state.security.affNets
    } else {
      return []
    }
  },

  isVisibleOptimisation: state => {
    if (state.security.isAdmin) {
      return state.security.isAdmin
    }
    if (state.security.isVisibleOptimisation) {
      return state.security.isVisibleOptimisation
    } else {
      return false
    }
  },
  isVisibleDsp: state => {
    if (state.security.isAdmin) {
      return state.security.isAdmin
    }
    if (state.security.isVisibleDsp) {
      return state.security.isVisibleDsp
    } else {
      return false
    }
  },

  isVisibleFlow: state => {
    // if (state.security.isAdmin) {
    //   return state.security.isAdmin
    // }
    if (state.security.isFlowAdmin) {
      return state.security.isFlowAdmin
    } else {
      return false
    }
  },

  isVisibleEarningsStat: state => {
    if (state.security.isVisibleEarningsStat) {
      return state.security.isVisibleEarningsStat
    } else {
      return false
    }
  },

  // isVisibleCabStat: state => {
  //   if (state.security.isAdmin) {
  //     return state.security.isAdmin
  //   }
  //   if (state.security.isVisibleCabStat) {
  //     return state.security.isVisibleCabStat
  //   } else {
  //     return false
  //   }
  // },

  isVisibleSpending: state => {
    if (state.security.isAdmin) {
      return state.security.isAdmin
    }
    if (state.security.isVisibleSpending) {
      return state.security.isVisibleSpending
    } else {
      return false
    }
  },

  access: state => {
    return state.access
  },

  getDspTeam: state => {
    if (state.security.isVisibleDsp) {
      return state.security.team
    } else {
      return -1
    }
  },

  getUserName: state => state.name,

  getLocale: state => state.settings.locale,

  getTelegramBotName: state => state.config.telegramBotName,

  getTelegramUserId: state => state.settings.telegram_user_id,

  getIsAllowEditCreatorAndWhoAdd: state => {
    return state.security.allowEditCreatorAndWhoAdd
  },

  getIsDspTeamModerator: state => {
    // console.log('state.security.listAllowModerateTeams', state.security.listAllowModerateTeams)
    // console.log('state.security.team', state.security.team)
    if (state.security.listAllowModerateTeams) {
      return state.security.listAllowModerateTeams.some(t => state.security.team === t)
    } else if (state.security.isDspTeamModerator) {
      return state.security.isDspTeamModerator
    }
  },

  isAllowModerateTeaser: state => teamId => {
    // console.log('state.security.listAllowModerateTeams', state.security.listAllowModerateTeams)
    // console.log('state.security.team', teamId)
    if (state.security.listAllowModerateTeams) {
      return state.security.listAllowModerateTeams.includes(teamId)
    }
  }
}

const actions = {
  [REQUEST]: async ({commit}) => {
    commit(REQUEST)
    try {
      const response = await api.getProfile()
      // window.console.info('Profile response', response.data)
      if (response.status === 202) {
        // window.console.info('Profile response', response.data)
        // window.console.info('POST response', response)
        commit(SUCCESS, response.data)
        // commit(SUCCESS, {profile: {}, settings: {}})
        return true
      } else {
        window.console.info('Error', response)
        commit(ERROR)
        await store.dispatch(AUTH_LOGOUT)
        return false
      }
    } catch (err) {
      window.console.error(err)
      // commit(ERROR)
      // store.dispatch(AUTH_LOGOUT)
      return false
    }
  }
}

const mutations = {
  [REQUEST]: state => {
    state.status = 'loading'
  },
  [SUCCESS]: (state, resp) => {
    state.status = 'success'
    Vue.set(state, 'profile', resp.profile)
    Vue.set(state, 'settings', resp.settings)
    Vue.set(state, 'security', resp.security)
    Vue.set(state, 'name', resp.name)
    Vue.set(state, 'access', resp.access)
    Vue.set(state, 'config', resp.config)
  },
  [ERROR]: state => {
    state.status = 'error'
  },

  [SET_LOCALE]: (state, value) => {
    state.settings.locale = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}