import Vue from 'vue'
// import store from '@/store'

import {
  AFFNETS_ADD_ITEM_WINDOW_OFF,
  AFFNETS_ADD_ITEM_WINDOW_ON,
  AFFNETS_ADD_ITEM_WINDOW_SWITCH,
  AFFNETS_REFRESH_OFF,
  AFFNETS_REFRESH_ON,
  AFFNETS_SET_FINALIZEDDATES,
  AFFNETS_TABLE_LOADING_OFF,
  AFFNETS_TABLE_LOADING_ON,
  API_ADD_ITEM_WINDOW_OFF,
  API_ADD_ITEM_WINDOW_ON,
  API_ADD_ITEM_WINDOW_SWITCH,
  CAB_ITEMS,
  CAB_VALUE,
  DRAWER_LEFT_OFF,
  DRAWER_LEFT_ON,
  DRAWER_LEFT_SWITCH,
  DRAWER_RIGHT_OFF,
  DRAWER_RIGHT_ON,
  DRAWER_RIGHT_SWITCH,
  DSP_IS_VISIBLE_STAT_FROM_DSP_OFF,
  DSP_IS_VISIBLE_STAT_FROM_DSP_ON,
  DSP_IS_VISIBLE_STAT_OFF,
  DSP_IS_VISIBLE_STAT_ON,
  DSP_SHOW_ONLY_EMPTY_TAGS_OFF,
  DSP_SHOW_ONLY_EMPTY_TAGS_ON,
  DSP_TEASERS_ADD_ITEM_WINDOW_OFF,
  DSP_TEASERS_ADD_ITEM_WINDOW_ON,
  DSP_TEASERS_ADD_ITEM_WINDOW_SWITCH,
  DSP_TEASERS_ALLOW_MASS_CHANGES_OFF,
  DSP_TEASERS_ALLOW_MASS_CHANGES_ON,
  DSP_TEASERS_MASS_CHANGES_OFF,
  DSP_TEASERS_MASS_CHANGES_ON,
  DSP_TEASERS_ON_MODERATION_OFF,
  DSP_TEASERS_ON_MODERATION_ON,
  DSP_TEASERS_REFRESH_OFF,
  DSP_TEASERS_REFRESH_ON,
  DSP_TEASERS_SOURCES_SET,
  DSP_TEASERS_TABLE_LOADING_OFF,
  DSP_TEASERS_TABLE_LOADING_ON,
  DST_SET_DEP,
  DST_SET_DEPS,
  EARNING_ADD_STATISTIC_WINDOW_OFF,
  EARNING_ADD_STATISTIC_WINDOW_ON,
  EARNING_ADD_STATISTIC_WINDOW_SWITCH,
  FLOW_REFRESH_OFF,
  FLOW_REFRESH_ON,
  FLOW_TABLE_LOADING_OFF,
  FLOW_TABLE_LOADING_ON,
  ITEMS_PER_PAGE,
  OPTIMIZATION_ADD_ITEM_WINDOW_OFF,
  OPTIMIZATION_ADD_ITEM_WINDOW_ON,
  OPTIMIZATION_ADD_ITEM_WINDOW_SWITCH,
  OPTIMIZATION_ADD_NEW_TASK_OFF,
  OPTIMIZATION_ADD_NEW_TASK_ON,
  OPTIMIZATION_ADD_NEW_TASK_SWITCH,
  OPTIMIZATION_FILTER_AUTOPRICE_OFF,
  OPTIMIZATION_FILTER_AUTOPRICE_ON,
  OPTIMIZATION_FILTER_OPTIOFF_OFF,
  OPTIMIZATION_FILTER_OPTIOFF_ON,
  OPTIMIZATION_ON_MODERATION_OFF,
  OPTIMIZATION_ON_MODERATION_ON,
  OPTIMIZATION_REFRESH_OFF,
  OPTIMIZATION_REFRESH_ON,
  OPTIMIZATION_TABLE_LOADING_OFF,
  OPTIMIZATION_TABLE_LOADING_ON,
  OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_OFF,
  OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_ON,
  OPTIMIZATION_TABLE_MASS_ACTION_ENABLE_OFF,
  OPTIMIZATION_TABLE_MASS_ACTION_ENABLE_ON,
  OPTIMIZATION_TABLE_MASS_ACTION_LOADING_OFF,
  OPTIMIZATION_TABLE_MASS_ACTION_LOADING_ON,
  OPTIMIZATION_TABLE_MASS_ACTION_OFF,
  OPTIMIZATION_TABLE_MASS_ACTION_ON,
  RUNNING_BACKDATING_UPDATE_SPENT_OFF,
  // RUNNING_BACKDATING_UPDATE_SPENT_ON,
  SET_RUNNING_BACKDATING_UPDATE_SPENT_STATUS,
  // SPENDING_CAMPAIGNS_LAST_UPDATE,
  SPENDING_CAMPAIGNS_REFRESH_OFF,
  SPENDING_CAMPAIGNS_REFRESH_ON,
  SPENDING_CAMPAIGNS_TABLE_LOADING_OFF,
  SPENDING_CAMPAIGNS_TABLE_LOADING_ON,
  SPENDING_LAST_UPDATE,
  URL_CHECKER_LAST_UPDATE,
  // PARENT_OFFER_ITEMS,
  // PARENT_OFFER_VALUE,
  // STAS_OFR_ITEMS,
  // STAS_OFR_VALUE
} from '../actions/iface'

const state = {
  drawerLeft: false,
  drawerRight: false,
  itemsPerPage: 10,
  optimizationAddItemWindowShow: false,
  optimizationAddNewTask: false,
  optimizationOnModeration: false,
  optimizationFilterAutoPrice: false,
  optimizationFilterOptimizationOff: false,
  optimizationRefresh: false,
  affNetsRefresh: false,

  finalizedDates: [],

  optimizationRefreshIsLoading: false,
  affNetsRefreshIsLoading: false,
  spendingCampaignsRefresh: false,

  optimizationTableMassActionsEnable: false,
  optimizationTableMassActionsAllow: false,
  optimizationTableMassActions: false,
  optimizationTableMassActionsIsLoading: false,

  spendingCampaignsRefreshIsLoading: false,
  cabValue: undefined,

  // stasOfrValue: undefined,
  // parentOfferValue: undefined,
  // parentOffersItems: [],
  // stasOfferItems: [],

  cabItems: [],
  spendingLastUpdate: -1,

  urlCheckerLastUpdate: -1,
  // spendingCampaignsLastUpdate: -1,
  isRunningBackdatingUpdateSpent: false,

  runningBackdatingUpdateSpentStatus: {},

  dspTeasersOnModeration: false,
  dspTeasersAddItemWindowShow: false,

  dspTeasersRefresh: false,
  dspTeasersRefreshIsLoading: false,

  dspTeasersAllowMassChanges: false,
  dspTeasersMassChanges: false,

  dspTeasersSources: [],

  flowRefresh: false,
  flowRefreshIsLoading: false,

  dspDep: null,
  dspDeps: [],
  dspShowOnlyEmptyTags: false,


  affNetsAddItemWindowShow: false,

  isVisibleStat: false,
  isShowStatFromDsp: false,

  earningAddStatisticWindowShow: false,

  apiAddItemWindowShow: false,
}

const getters = {
  getDrawerLeft: state => state.drawerLeft,
  getDrawerRight: state => state.drawerRight,
  getItemsPerPage: state => state.itemsPerPage,

  // parentOfferValue: state => state.parentOfferValue,
  // parentOfferItems: state => state.parentOffersItems,

  // stasOfrValue: state => state.stasOfrValue,
  // stasOfferItems: state => state.stasOfferItems,

  cabValue: state => state.cabValue,
  cabItems: state => state.cabItems,

  isVisibleOptimizationAddItemWindowShow: state => state.optimizationAddItemWindowShow,
  isVisibleOptimizationAddNewTaskWindow: state => state.optimizationAddNewTask,
  isVisibleDspTeasersAddItemWindowShow: state => state.dspTeasersAddItemWindowShow,
  isVisibleEarningAddStatisticWindowShow: state => state.earningAddStatisticWindowShow,
  isVisibleApiAddItemWindowShow: state => state.apiAddItemWindowShow,

  isVisibleAffNetsAddItemWindowShow: state => state.affNetsAddItemWindowShow,

  isOptimizationOnModeration: state => state.optimizationOnModeration,
  filterAutoPriceIsOff: state => state.optimizationFilterAutoPrice,
  filterOptimizationIsOff: state => state.optimizationFilterOptimizationOff,

  getOptimizationRefreshStatus: state => state.optimizationRefresh,
  getOptimizationRefreshIsLoading: state => state.optimizationRefreshIsLoading,

  getAffNetsRefreshStatus: state => state.affNetsRefresh,
  getAffNetsRefreshIsLoading: state => state.affNetsRefreshIsLoading,
  getFinalizedDates: state => state.finalizedDates,

  getOptimizationTableMassActionEnableStatus: state => state.optimizationTableMassActionsEnable,
  getOptimizationTableMassActionAllowStatus: state => state.optimizationTableMassActionsAllow,
  getOptimizationTableMassActionStatus: state => state.optimizationTableMassActions,
  getOptimizationTableMassActionIsLoading: state => state.optimizationTableMassActionsIsLoading,

  getSpendingCampaignsRefreshStatus: state => state.spendingCampaignsRefresh,
  getSpendingCampaignsRefreshIsLoading: state => state.spendingCampaignsRefreshIsLoading,

  getSpendingLastUpdate: state => state.spendingLastUpdate,
  getUrlCheckerLastUpdate: state => state.urlCheckerLastUpdate,
  // getSpendingCampaignsLastUpdate: state => state.spendingCampaignsLastUpdate,

  getIsRunningBackdatingUpdateSpent: state => state.isRunningBackdatingUpdateSpent,
  getRunningBackdatingUpdateSpentStatus: state => state.runningBackdatingUpdateSpentStatus,

  filterDSPShowOnlyEmptyTags: state => state.dspShowOnlyEmptyTags,

  getIsVisibleStatStatus: state => state.isVisibleStat,
  isShowStatFromDsp: state => state.isShowStatFromDsp,

  isDspTeasersOnModeration: state => state.dspTeasersOnModeration,
  isAllowDspTeasersAllowMassChanges: state => state.dspTeasersAllowMassChanges,
  getDspTeasersMassChanges: state => state.dspTeasersMassChanges,

  getDspTeasersRefreshStatus: state => state.dspTeasersRefresh,
  getDspTeasersRefreshIsLoading: state => state.dspTeasersRefreshIsLoading,

  getDspTeasersSources: state => state.dspTeasersSources,

  getFlowRefreshStatus: state => state.flowRefresh,
  getFlowRefreshIsLoading: state => state.flowRefreshIsLoading,

  getDspDep: state => state.dspDep,
  getDspDeps: state => state.dspDeps,

  getDspDepTeams: state => {
    if (state.dspDep !== null) return state.dspDep
    return {value: []}
  },

}

const actions = {}

const mutations = {
  [DRAWER_LEFT_SWITCH]: state => state.drawerLeft = !state.drawerLeft,
  [DRAWER_LEFT_ON]: state => state.drawerLeft = true,
  [DRAWER_LEFT_OFF]: state => state.drawerLeft = false,

  [DRAWER_RIGHT_SWITCH]: state => state.drawerRight = !state.drawerRight,
  [DRAWER_RIGHT_ON]: state => state.drawerRight = true,
  [DRAWER_RIGHT_OFF]: state => state.drawerRight = false,

  [ITEMS_PER_PAGE]: (state, value) => state.itemsPerPage = value,

  [CAB_VALUE]: (state, value) => state.cabValue = value,
  [CAB_ITEMS]: (state, value) => Vue.set(state, 'cabItems', value),
  [SPENDING_LAST_UPDATE]: (state, value) => state.spendingLastUpdate = value,
  // [SPENDING_CAMPAIGNS_LAST_UPDATE]: (state, value) => state.spendingCampaignsLastUpdate = value,
  [URL_CHECKER_LAST_UPDATE]: (state, value) => state.urlCheckerLastUpdate = value,

  [OPTIMIZATION_ADD_ITEM_WINDOW_ON]: state => state.optimizationAddItemWindowShow = true,
  [OPTIMIZATION_ADD_ITEM_WINDOW_OFF]: state => state.optimizationAddItemWindowShow = false,

  [OPTIMIZATION_ADD_ITEM_WINDOW_SWITCH]: state =>
    state.optimizationAddItemWindowShow = !state.optimizationAddItemWindowShow,

  [OPTIMIZATION_ADD_NEW_TASK_ON]: state => state.optimizationAddNewTask = true,
  [OPTIMIZATION_ADD_NEW_TASK_OFF]: state => state.optimizationAddNewTask = false,

  [OPTIMIZATION_ADD_NEW_TASK_SWITCH]: state =>
    state.optimizationAddNewTask = !state.optimizationAddNewTask,

  [OPTIMIZATION_REFRESH_ON]: state => state.optimizationRefresh = true,
  [OPTIMIZATION_REFRESH_OFF]: state => state.optimizationRefresh = false,

  [OPTIMIZATION_TABLE_LOADING_ON]: state => state.optimizationRefreshIsLoading = true,
  [OPTIMIZATION_TABLE_LOADING_OFF]: state => state.optimizationRefreshIsLoading = false,

  [AFFNETS_REFRESH_ON]: state => state.affNetsRefresh = true,
  [AFFNETS_REFRESH_OFF]: state => state.affNetsRefresh = false,

  [AFFNETS_TABLE_LOADING_ON]: state => state.affNetsRefreshIsLoading = true,
  [AFFNETS_TABLE_LOADING_OFF]: state => state.affNetsRefreshIsLoading = false,

  [AFFNETS_SET_FINALIZEDDATES]: (state, value) => Vue.set(state, 'finalizedDates', value),

  [OPTIMIZATION_TABLE_MASS_ACTION_ENABLE_ON]: state => state.optimizationTableMassActionsEnable = true,
  [OPTIMIZATION_TABLE_MASS_ACTION_ENABLE_OFF]: state => state.optimizationTableMassActionsEnable = false,

  [OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_ON]: state => state.optimizationTableMassActionsAllow = true,
  [OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_OFF]: state => state.optimizationTableMassActionsAllow = false,

  [OPTIMIZATION_TABLE_MASS_ACTION_ON]: state => state.optimizationTableMassActions = true,
  [OPTIMIZATION_TABLE_MASS_ACTION_OFF]: state => state.optimizationTableMassActions = false,

  [OPTIMIZATION_TABLE_MASS_ACTION_LOADING_ON]: state => state.optimizationTableMassActionsIsLoading = true,
  [OPTIMIZATION_TABLE_MASS_ACTION_LOADING_OFF]: state => state.optimizationTableMassActionsIsLoading = false,

  [SPENDING_CAMPAIGNS_REFRESH_ON]: state => state.spendingCampaignsRefresh = true,
  [SPENDING_CAMPAIGNS_REFRESH_OFF]: state => state.spendingCampaignsRefresh = false,

  [SPENDING_CAMPAIGNS_TABLE_LOADING_ON]: state => state.spendingCampaignsRefreshIsLoading = true,
  [SPENDING_CAMPAIGNS_TABLE_LOADING_OFF]: state => state.spendingCampaignsRefreshIsLoading = false,

  [OPTIMIZATION_ON_MODERATION_ON]: state => state.optimizationOnModeration = true,
  [OPTIMIZATION_ON_MODERATION_OFF]: state => state.optimizationOnModeration = false,

  [OPTIMIZATION_FILTER_AUTOPRICE_ON]: state => state.optimizationFilterAutoPrice = true,
  [OPTIMIZATION_FILTER_AUTOPRICE_OFF]: state => state.optimizationFilterAutoPrice = false,

  [OPTIMIZATION_FILTER_OPTIOFF_ON]: state => state.optimizationFilterOptimizationOff = true,
  [OPTIMIZATION_FILTER_OPTIOFF_OFF]: state => state.optimizationFilterOptimizationOff = false,

  [SET_RUNNING_BACKDATING_UPDATE_SPENT_STATUS]: (state, value) => {
    Vue.set(state, 'runningBackdatingUpdateSpentStatus', value)
    state.isRunningBackdatingUpdateSpent = true
  },

  [RUNNING_BACKDATING_UPDATE_SPENT_OFF]: state => {
    state.isRunningBackdatingUpdateSpent = false
    Vue.set(state, 'runningBackdatingUpdateSpentStatus', {})
  },

  [DSP_TEASERS_ADD_ITEM_WINDOW_ON]: state => state.dspTeasersAddItemWindowShow = true,
  [DSP_TEASERS_ADD_ITEM_WINDOW_OFF]: state => state.dspTeasersAddItemWindowShow = false,

  [DSP_TEASERS_ADD_ITEM_WINDOW_SWITCH]: state =>
    state.dspTeasersAddItemWindowShow = !state.dspTeasersAddItemWindowShow,


  [AFFNETS_ADD_ITEM_WINDOW_ON]: state => state.affNetsAddItemWindowShow = true,
  [AFFNETS_ADD_ITEM_WINDOW_OFF]: state => state.affNetsAddItemWindowShow = false,

  [AFFNETS_ADD_ITEM_WINDOW_SWITCH]: state =>
    state.affNetsAddItemWindowShow = !state.affNetsAddItemWindowShow,

  [DSP_TEASERS_ON_MODERATION_ON]: state => state.dspTeasersOnModeration = true,
  [DSP_TEASERS_ON_MODERATION_OFF]: state => state.dspTeasersOnModeration = false,

  [DSP_TEASERS_REFRESH_ON]: state => state.dspTeasersRefresh = true,
  [DSP_TEASERS_REFRESH_OFF]: state => state.dspTeasersRefresh = false,

  [DSP_TEASERS_TABLE_LOADING_ON]: state => state.dspTeasersRefreshIsLoading = true,
  [DSP_TEASERS_TABLE_LOADING_OFF]: state => state.dspTeasersRefreshIsLoading = false,

  [DSP_SHOW_ONLY_EMPTY_TAGS_ON]: state => state.dspShowOnlyEmptyTags = true,
  [DSP_SHOW_ONLY_EMPTY_TAGS_OFF]: state => state.dspShowOnlyEmptyTags = false,


  [DSP_IS_VISIBLE_STAT_ON]: state => state.isVisibleStat = true,
  [DSP_IS_VISIBLE_STAT_OFF]: state => state.isVisibleStat = false,

  [DSP_IS_VISIBLE_STAT_FROM_DSP_ON]: state => state.isShowStatFromDsp = true,
  [DSP_IS_VISIBLE_STAT_FROM_DSP_OFF]: state => state.isShowStatFromDsp = false,

  [DSP_TEASERS_ALLOW_MASS_CHANGES_ON]: state => state.dspTeasersAllowMassChanges = true,
  [DSP_TEASERS_ALLOW_MASS_CHANGES_OFF]: state => state.dspTeasersAllowMassChanges = false,

  [DSP_TEASERS_MASS_CHANGES_ON]: state => state.dspTeasersMassChanges = true,
  [DSP_TEASERS_MASS_CHANGES_OFF]: state => state.dspTeasersMassChanges = false,

  [DSP_TEASERS_SOURCES_SET]: (state, value) => {
    Vue.set(state, 'dspTeasersSources', value)
  },

  [API_ADD_ITEM_WINDOW_ON]: state => state.apiAddItemWindowShow = true,
  [API_ADD_ITEM_WINDOW_OFF]: state => state.apiAddItemWindowShow = false,

  [API_ADD_ITEM_WINDOW_SWITCH]: state =>
    state.apiAddItemWindowShow = !state.apiAddItemWindowShow,

  [FLOW_REFRESH_ON]: state => state.flowRefresh = true,
  [FLOW_REFRESH_OFF]: state => state.flowRefresh = false,

  [FLOW_TABLE_LOADING_ON]: state => state.flowRefreshIsLoading = true,
  [FLOW_TABLE_LOADING_OFF]: state => state.flowRefreshIsLoading = false,

  [DST_SET_DEP]: (state, value) => {
    Vue.set(state, 'dspDep', value)
  },

  [DST_SET_DEPS]: (state, value) => {
    Vue.set(state, 'dspDeps', value)
  },

  [EARNING_ADD_STATISTIC_WINDOW_ON]: state => state.earningAddStatisticWindowShow = true,
  [EARNING_ADD_STATISTIC_WINDOW_OFF]: state => state.earningAddStatisticWindowShow = false,

  [EARNING_ADD_STATISTIC_WINDOW_SWITCH]: state =>
    state.earningAddStatisticWindowShow = !state.earningAddStatisticWindowShow,

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}